@font-face {
  font-family: rubikBold;
  src: url("./../../assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}
@font-face {
  font-family: rubikReg;
  src: url("./../../assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}
@font-face {
  font-family: rubikBlack;
  src: local("Rubik-Black"), url("./../../assets/fonts/Rubik/Rubik-Black.ttf") format("truetype");
}
@font-face {
  font-family: rubikLight;
  src: local("Rubik-Light"), url("./../../assets/fonts/Rubik/Rubik-Light.ttf") format("truetype");
}
@font-face {
  font-family: arizonia;
  src: local("Arizonia-Regular"), url("./../../assets/fonts/Arizonia/Arizonia-Regular.ttf") format("truetype");
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.heading-pri {
  font-family: rubikBold;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.heading-med {
  font-family: rubikBold;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.heading-reg {
  font-family: rubikBold;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.heading-light {
  font-family: rubikLight;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.heading-sec {
  font-family: rubikLight;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.label-text {
  font-family: rubikReg;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  color: #000;
  margin-bottom: 2%;
}

.input-box {
  width: 100%;
}

.grey-small {
  font-family: rubikReg;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  color: #666;
  margin-bottom: 2%;
}

.btn-app {
  color: #fff;
  background: #eeba00;
  font-size: 14px;
  font-family: nunitoReg;
  border: 1px solid #eeba00;
  border-radius: 8px;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-family: rubikBold;
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
  font-style: normal;
  color: #fff;
  padding: 2%;
  width: 50%;
}

.small-text {
  font-family: rubikReg;
  font-size: 12px;
  font-weight: 700;
  line-height: 23px;
  font-style: normal;
  color: #000;
}

.smallest-text {
  font-family: rubiklight;
  font-size: 8px;
  font-weight: 700;
  line-height: 23px;
  font-style: normal;
  color: #000;
}

.input-field {
  margin: 2% 0;
  width: 40%;
}

.blue-bold {
  font-family: rubikBold;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  font-style: normal;
  color: #082d72;
  cursor: pointer;
}

.p-float-label {
  margin-top: 2%;
}

.empty-page {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 320px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (max-width: 360px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 361px) and (max-width: 375px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 411px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 411px) and (max-width: 475px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 475px) and (max-width: 494px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}
@media only screen and (min-width: 495px) and (max-width: 560px) {
  .heading-pri {
    font-family: rubikBold;
    font-size: 18px;
    font-weight: 700;
    line-height: 72px;
    font-style: normal;
    color: #000;
    margin-bottom: 2%;
  }
}