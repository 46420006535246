@import "./theme.scss";

.p-password {
  width: 100% !important;
}
.p-password-input {
  width: 100%;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: $theme-secondary !important;
}
.p-dropdown {
  width: 50% !important;
}

.p-autocomplete {
  width: 50% !important;
}
.p-datatable .p-datatable-tbody > tr > td {
  height: auto;
}
button:disabled {
  background-color: $grey-text !important;
  border-color: $grey-text !important;
  color: $white !important;
}

@media only screen and (max-width: 320px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (max-width: 360px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 361px) and (max-width: 375px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 375px) and (max-width: 411px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
// ! Mobile General Queries
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 411px) and (max-width: 475px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ! optional Mobile Screen
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 475px) and (max-width: 494px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}

// ! Landscape of Mobile Screens and Few Tab Screens till 495px-914px
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 495px) and (max-width: 560px) {
  .p-dropdown {
    width: 100% !important;
  }

  .p-autocomplete {
    width: 100% !important;
  }
}
