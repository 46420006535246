$black: #000;
$white: #fff;
$grey-text: #666;
$grey-dark: #333;
$grey-light: #eaeaea;
$grey-bg: #f5f5f5;
$theme-primary: #082d72;
$theme-secondary: #eeba00;
@font-face {
  font-family: rubikBold;
  src: url("./../../assets/fonts/Rubik/Rubik-Bold.ttf") format("truetype");
}
@font-face {
  font-family: rubikReg;
  src: url("./../../assets/fonts/Rubik/Rubik-Regular.ttf") format("truetype");
}

@font-face {
  font-family: rubikBlack;
  src: local("Rubik-Black"),
    url("./../../assets/fonts/Rubik/Rubik-Black.ttf") format("truetype");
}

@font-face {
  font-family: rubikLight;
  src: local("Rubik-Light"),
    url("./../../assets/fonts/Rubik/Rubik-Light.ttf") format("truetype");
}

@font-face {
  font-family: arizonia;
  src: local("Arizonia-Regular"),
    url("./../../assets/fonts/Arizonia/Arizonia-Regular.ttf") format("truetype");
}

// mixins
@mixin primary-btn($bg, $font-color, $font-size) {
  color: $font-color;
  background: $bg;
  font-size: $font-size;
  font-family: nunitoReg;
  border: 1px solid $bg;
  border-radius: 8px;
  box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

@mixin h-text($fontFamily, $size, $weight, $linehignt, $style, $color) {
  font-family: $fontFamily;
  font-size: $size;
  font-weight: $weight;
  line-height: $linehignt;
  font-style: $style;
  color: $color;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.heading-pri {
  @include h-text(rubikBold, 32px, 700, 38px, normal, $black);
  margin-bottom: 2%;
}
.heading-med {
  @include h-text(rubikBold, 18px, 700, 30px, normal, $black);
  margin-bottom: 2%;
}

.heading-reg {
  @include h-text(rubikBold, 14px, 700, 30px, normal, $black);
  margin-bottom: 2%;
}

.heading-light {
  @include h-text(rubikLight, 18px, 700, 30px, normal, $black);
  margin-bottom: 2%;
}

.heading-sec {
  @include h-text(rubikLight, 24px, 700, 28px, normal, $black);
  margin-bottom: 2%;
}
.label-text {
  @include h-text(rubikReg, 14px, 400, 24px, normal, $black);
  margin-bottom: 2%;
}
.input-box {
  width: 100%;
}
.grey-small {
  @include h-text(rubikReg, 14px, 700, 22px, normal, $grey-text);
  margin-bottom: 2%;
}

.btn-app {
  @include primary-btn($theme-secondary, $white, 14px);
  @include h-text(rubikBold, 14px, 700, 23px, normal, $white);
  padding: 2%;
  width: 50%;
}

.small-text {
  @include h-text(rubikReg, 12px, 700, 23px, normal, $black);
}

.smallest-text {
  @include h-text(rubiklight, 8px, 700, 23px, normal, $black);
}
.input-field {
  margin: 2% 0;
  width: 40%;
}

.blue-bold {
  @include h-text(rubikBold, 14px, 400, 23px, normal, $theme-primary);
  cursor: pointer;
}

.p-float-label {
  margin-top: 2%;
}
.empty-page {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 320px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (max-width: 360px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 361px) and (max-width: 375px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 375px) and (max-width: 411px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
// ! Mobile General Queries
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 411px) and (max-width: 475px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ! optional Mobile Screen
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 475px) and (max-width: 494px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}

// ! Landscape of Mobile Screens and Few Tab Screens till 495px-914px
// ? ---------------------------------- Media Query for minimun 320px and Maximum 360px ---------------------------------------
@media only screen and (min-width: 495px) and (max-width: 560px) {
  .heading-pri {
    @include h-text(rubikBold, 18px, 700, 72px, normal, $black);
    margin-bottom: 2%;
  }
}
